window.$ = window.jQuery = require('jquery')

const header = $('.site-header')
const toggler = $('.site-header__toggler')
const menu = $('.site-header__menu')
const hashTarget = window.location.hash

/**
 * Scroll anchors
 */
function scrollToDiv(className, animate = true) {
    let target = $(className)

    if (target.length) {
        $('html, body').animate({ scrollTop: target.offset().top - 60 }, animate ? 1000 : 100)
    }
}

$(document).on('click', '[data-scroll]', e => {
    let me = e.currentTarget.dataset.scroll
    scrollToDiv(`#section-${me}`)
})

/**
 * Sticky header
 */
$(window).on('scroll', e => {
    e.currentTarget.scrollY > 150 ? header.addClass('is-sticky') : header.removeClass('is-sticky')
})

/**
 * Mobile menu
 */
if (toggler && menu) {
    toggler.on('click', e => {
        e.preventDefault()

        toggler.toggleClass('is-open')
        !toggler.hasClass('is-open') ? menu.slideUp(250) : menu.slideDown(250)
    })

    if ($(window).width() < 640) {
        menu.find('a').on('click', () => {
            toggler.removeClass('is-open')
            menu.hide()
        })
    }

    $(window).on('resize', e => {
        if (e.currentTarget.innerWidth > 640) {
            menu.show()
        } else {
            if (!toggler.hasClass('is-open')) {
                menu.hide()
            }
        }
    })
}

/**
 * Jump to validated section
 */
if (hashTarget && hashTarget.length > 3) {
    scrollToDiv(hashTarget, false)
}
